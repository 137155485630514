.salary-calculation {
  background-color: #f5f5f7;
  min-height: 100vh;
  padding: 40px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.sc-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  text-align: left;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
}

.sc-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #1d1d1f;
}

.sc-title-highlight {
  color: #0071e3;
}

.sc-back-button {
  background-color: #0071e3;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 30px;
  position: absolute;
  left: calc((100% - 980px) / 2 + 20px);
}

.sc-back-button:hover {
  background-color: #0077ed;
}

.sc-content {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 60px;
}

.sc-month-selector select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
}

.sc-salary-details {
  background-color: #fff;
  border-radius: 18px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sc-salary-details h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1d1d1f;
}

.sc-salary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.sc-salary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f5f5f7;
  border-radius: 8px;
}

.sc-item-label {
  color: #1d1d1f;
  font-size: 16px;
  font-weight: 400;
}

.sc-item-value {
  font-weight: 600;
  font-size: 16px;
  text-align: right;
}

.sc-item-value.positive {
  color: #34c759;
}

.sc-item-value.negative {
  color: #ff3b30;
}

.sc-salary-note {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f7;
  border-radius: 8px;
  color: #86868b;
}

.sc-selectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.sc-selector {
  display: flex;
  flex-direction: column;
}

.sc-selector label {
  margin-bottom: 8px;
  color: #1d1d1f;
  font-size: 14px;
}

.sc-selector select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .sc-title {
    font-size: 32px;
  }
  
  .sc-content {
    padding: 20px;
  }
  
  .sc-salary-details {
    padding: 20px;
  }
}

.sc-file-upload {
  margin-bottom: 30px;
  position: relative;
}

.sc-file-input {
  width: 100%;
  padding: 20px;
  background-color: white;
  border: 2px dashed #d2d2d7;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sc-file-input:hover {
  border-color: #0071e3;
  background-color: #f5f5f7;
}

.sc-file-input:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.sc-upload-status {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #0071e3;
  font-weight: 500;
}
