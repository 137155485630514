.cutting-edge {
  max-width: 980px;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: relative;
}

.ce-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.ce-title {
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  margin: 0;
}

.ce-translation-recommendation {
  display: flex;
  align-items: center;
}

.ce-translation-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.ce-translation-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.ce-translation-text p {
  margin: 0 0 3px 0;
  font-size: 12px;
}

.ce-translation-button {
  display: inline-block;
  padding: 5px 10px;
  background-color: #0071e3;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  font-size: 12px;
  transition: background-color 0.3s;
}

.ce-translation-button:hover {
  background-color: #0077ed;
}

.ce-back-button {
  display: inline-block;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 8px 16px;
  background-color: #0071e3;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  text-align: left;
  position: absolute;
  left: 20px;
  z-index: 10;
}

.ce-back-button:hover {
  background-color: #0077ed;
}

.ce-grid {
  column-count: 3;
  column-gap: 30px;
  margin-top: 30px;
}

.ce-article-item {
  background-color: #f5f5f7;
  border-radius: 18px;
  padding: 20px;
  transition: transform 0.3s;
  break-inside: avoid;
  margin-bottom: 30px;
}

.ce-article-item:hover {
  transform: scale(1.03);
}

.ce-article-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ce-article-journal,
.ce-article-category,
.ce-article-date {
  font-size: 14px;
  color: #86868b;
  margin-bottom: 5px;
}

.ce-article-summary-container {
  position: relative;
  height: 100px;
  overflow: hidden;
  background-color: #F5F5F7;
}

.ce-article-summary {
  margin: 0;
  padding: 10px;
  background-color: #F5F5F7;
}

.ce-article-summary-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(to bottom, rgba(245,245,247,0), rgba(245,245,247,1));
}

.ce-article-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #0071e3;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.ce-article-link:hover {
  background-color: #0077ed;
}

.ce-translation-link {
  color: #0071e3;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.ce-translation-link:hover {
  text-decoration: underline;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .ce-grid {
    column-count: 2;
  }
}

@media (max-width: 768px) {
  .ce-grid {
    column-count: 1;
  }
}

.ce-load-more {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #0071e3;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ce-load-more:hover {
  background-color: #0077ed;
}

.ce-collapsible {
  margin-bottom: 50px;
}

.ce-collapsible-button {
  width: 100%;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-radius: 50px; */
  transition: background-color 0.3s;
}

.ce-collapsible-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.ce-collapsible-arrow {
  margin-left: auto;
}

.ce-content {
  margin-top: 100px;
}

.ce-search-button:hover {
  background-color: #0077ed;
}

.ce-title-highlight {
  color: #6E6E73;
}

.water-ripple-loading {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 100px auto;
  background-color: #f8f8f8;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0 0 50px rgba(0,0,0,0.1);
}

.water-ripple-loading::before,
.water-ripple-loading::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.water-ripple-loading::before {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  animation: milk-drop 4s ease-out infinite;
}

.water-ripple-loading::after {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  animation: milk-drop 4s ease-out infinite 2s;
}

@keyframes milk-drop {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 400px;
    height: 400px;
    opacity: 0;
  }
}

.ripple-loader {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 100px auto;
}

.ripple {
  position: absolute;
  border: 4px solid #0071e3;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.bouncing-ball-loader {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 50px auto;
}

.ball {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: radial-gradient(circle at 30% 30%, #ffffff, #e0e0e0);
  left: 25px;
  bottom: 25px;
  animation: bounce 2.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  z-index: 2;
}

.shadow {
  position: absolute;
  width: 70px;
  height: 14px;
  background: radial-gradient(ellipse, rgba(0,0,0,0.2), rgba(0,0,0,0));
  border-radius: 50%;
  bottom: -20px;
  left: 25px;
  animation: shadow 2.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  z-index: 1;
}

@keyframes bounce {
  0%, 100% {
    bottom: 25px;
    height: 66px;
    width: 74px;
  }
  50% {
    bottom: 65px;
    height: 70px;
    width: 70px;
  }
}

@keyframes shadow {
  0%, 100% {
    transform: scaleX(1);
    opacity: 0.4;
  }
  50% {
    transform: scaleX(0.8);
    opacity: 0.2;
  }
}

.paper-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.paper-card h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.paper-author, .paper-year {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.paper-summary {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 8px;
}

.paper-link {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
}

.paper-link:hover {
  background-color: #0056b3;
}
