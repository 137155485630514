/* src/App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.8);
  position: sticky;
  top: 0;
  z-index: 1001;
}

.header-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 980px;
}

.header-left, .header-center, .header-right {
  flex: 1;
  display: flex;
  align-items: center;
}

.header-center {
  justify-content: center;
}

.header-right {
  justify-content: flex-end;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icon {
  color: #f5f5f7;
  font-size: 16px;
  margin-left: 15px;
  cursor: pointer;
}

.mobile-menu-icon {
  color: #f5f5f7;
  font-size: 20px;
  margin-left: 15px;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    flex-basis: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .header-container {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-left {
    flex: 0 0 auto;
  }

  .header-center {
    display: none;
  }

  .header-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  .mobile-menu-icon {
    display: block;
    margin-left: 20px;
  }

  .mobile-menu {
    display: block;
  }

  .mobile-nav ul {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-nav li {
    margin: 15px 0;
    width: 100%;
  }

  .mobile-nav a {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
  }
}

.logo {
  display: flex;
  align-items: center;
  min-width: 100px;
}
.logo {
  height: 32px; /* 稍微减小 logo 高度 */
}

.main-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.main-nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  justify-content: center;
}

.main-nav li {
  position: relative;
}

.main-nav a {
  color: #f5f5f7;
  text-decoration: none;
  font-size: 12px;
  padding: 0 10px;
  white-space: nowrap;
}

.main-nav a:hover {
  opacity: 1;
}

.banner {
  background-color: #1d1d1f;
  color: #f5f5f7;
  text-align: center;
  padding: 12px 0;
 
}

.banner a {
  color: #2997ff;
  text-decoration: none;
}

.banner a:hover {
  text-decoration: underline;
}

.App-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-width: 980px;
  margin: 0 auto;
}

.app-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 18px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  text-align: center;
}

.quarter-grid {
  display: flex;
  gap: 10px;
}

.quarter {
  flex: 1;
  height: 500px;
}

.banner-content {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.banner-content h2 {
  margin-bottom: 10px;
}

.banner-content p {
  margin-bottom: 20px;
}

.quarter .banner-content {
  position: relative;
  top: 0;
  left: 0;
}

.banner-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  height: auto;
}

.large .banner-image, .gradient .banner-image, .product .banner-image {
  width: 100%;
  height: 100%;
 
}

.quarter .banner-image {
  width: 100%;
  height: 70%;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.banner-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.banner-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.learn-more {
  background-color: #0071e3;
  color: white;
}

.buy-now {
  background-color: transparent;
  color: #0071e3;
}

/* 特定样式 */
.large {
  height: 600px;
  background: #000000;
  color: white;
}

.gradient {
  height: 600px;
  background: linear-gradient(to bottom, #f5f5f7, #ffffff);
  color: black;
}

.product {
  height: 600px;
  background-color: #f5f5f7;
  color: black;
}

.dark {
  background-color: #000000;
  color: white;
}

.lifestyle {
  background-color: #fbfbfd;
  color: black;
}

.minimal {
  background-color: #ffffff;
  border: 1px solid #d2d2d7;
  color: black;
}

.wearable {
  background-color: #f2f2f2;
  color: black;
}

.App-footer {
  background-color: #f5f5f7;
  color: #6e6e73;
  font-size: 12px;
 
}

.footer-content {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  text-align: left;
}

.footer-columns {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d2d2d7;
}

.footer-column {
  flex-basis: 100%;
  margin-bottom: 0;
  text-align: left;
  padding: 0;
  border-bottom: 1px solid #d2d2d7;
}

.footer-column:last-child {
  border-bottom: none;
  width: 100%
}

.footer-column h3 {
  font-size: 12px;
  margin-bottom: 10px;
  color: #1d1d1f;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-column li {
  margin-bottom: 8px;
  
}

.footer-column a {
  color: #424245;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-column-links {
  display: none;
  padding: 0 0 10px 0;
  margin: 0;
}

.footer-column-links.expanded {
  display: block;
}

.footer-bottom {
  padding-top: 20px;
  font-size: 12px;
}

.footer-legal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.footer-legal-right {
  display: flex;
}

.footer-legal-right a {
  color: #424245;
  text-decoration: none;
  border-right: 1px solid #d2d2d7;
  padding-right: 10px;
  margin-right: 10px;
}

.footer-legal-right a:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

/* .footer-china {
 
} */

.main-nav .search-icon {
  width: 16px;
  height: 16px;
  background-image: url('./assets/icons/icon_search_default.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

/* 菜单图标样式 */
.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon-bar {
  width: 25px;
  height: 3px;
  background-color: #f5f5f7;
  margin: 4px 0;
}

/* 隐藏菜单项 */
.nav-items {
  display: flex;
}

.nav-items.open {
  display: block;
}

@media (max-width: 768px) {
  .footer-column-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    text-align: left;
  }

  .footer-column-links {
    display: none;
    padding-left: 20px;
  }

  .footer-column-links.expanded {
    display: block;
  }

  .arrow {
    border: solid #6e6e73;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.3s ease;
  }

  .arrow.down {
    transform: rotate(45deg);
  }

  .arrow.up {
    transform: rotate(-135deg);
  }
}

@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    flex-basis: 100%;
    margin-bottom: 20px;
    text-align: left;
  }

  .header-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .header-left {
    flex: 0 0 auto;
  }

  .header-center {
    display: none;
  }

  .header-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  .mobile-menu-icon {
    display: block;
    margin-left: 20px;
  }

  .mobile-menu {
    display: block;
  }

  .mobile-nav ul {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-nav li {
    margin: 15px 0;
    width: 100%;
  }

  .mobile-nav a {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
  }
}

.mobile-menu-icon {
  display: none;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #1d1d1f;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
}

.mobile-menu.open {
  right: 0;
}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #f5f5f7;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer-column {
    border-bottom: 1px solid #d2d2d7;
    padding: 0;
    width: 100%
  }

  .footer-column:last-child {
    border-bottom: none;
  }

  .footer-column-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    text-align: left;
  }

  .footer-column-title h3 {
    margin: 0;
    font-size: 12px;
    font-weight: normal;
  }

  .footer-column-arrow {
    font-size: 12px;
  }

  .footer-column-links {
    display: none;
    padding: 0 0 10px 0;
    margin: 0;
  }

  .footer-column-links.expanded {
    display: block;
  }

  .footer-column-links li {
    margin: 8px 0;
  }

  .footer-column-links a {
    font-size: 12px;
    color: #424245;
    text-decoration: none;
  }
}

.footer-column-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
}

.footer-column-text {
  text-align: left;
}

.footer-column-arrow {
  margin-left: auto;
}

@media (min-width: 769px) {
  .footer-columns {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .footer-column {
    flex-basis: calc(16.666% - 20px);
    border-bottom: none;
  }

  .footer-column-title {
    cursor: default;
  }

  .footer-column-arrow {
    display: none;
  }

  .footer-column-links {
    display: block;
  }
}

.all-software-page {
  max-width: 980px;
  margin: 0 auto;
  padding: 40px 20px;
}

.headline-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.headline {
  flex: 1;
}

.headline-text {
  font-size: 48px;
  font-weight: bold;
  color: #1d1d1f;
  text-align: left;
  line-height: 1.2;
  margin: 0;
}

.headline-text-gray {
  color: #6e6e73;
}

.customer-support {
  flex: 0 0 auto;
  margin-left: 40px;
}

@media (max-width: 768px) {
  .headline-container {
    flex-direction: column;
  }

  .headline {
    margin-bottom: 20px;
  }

  .headline-text {
    font-size: 36px;
    text-align: left;
  }

  .customer-support {
    width: 100%;
  }
}

.customer-support {
  flex: 0 0 auto;
  margin-left: 40px;
}

.support-text {
  margin-left: 10px;
}

.support-text p {
  margin: 0;
  font-size: 14px;
}

.support-text a,
.store-locator a {
  color: #0066cc;
  text-decoration: none;
  font-size: 14px;
}

.store-locator {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.store-locator img {
  margin-right: 5px;
}

.quick-links-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
}

.quick-links-grid a {
  color: #0066cc;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.4;
}

.quick-links-grid a:hover {
  text-decoration: underline;
}

.search-overlay h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #0066cc;
}

@media (max-width: 768px) {
  body.search-open {
    overflow: hidden;
  }

  .App-main {
    padding: 10px;
  }

  .quarter-grid {
    flex-direction: column;
  }

  .quarter {
    height: auto;
  }

  .banner-content h2 {
    font-size: 24px;
  }

  .banner-content p {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }

  .App-header {
    padding: 10px 0;
  }

  .main-nav a {
    font-size: 14px;
    padding: 0 8px;
  }

  .banner-buttons button {
    padding: 12px 20px;
    font-size: 16px;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS specific styles */
  .App-main {
    -webkit-overflow-scrolling: touch;
  }

  /* 修复 iOS 上可能出现的点击延迟 */
  .banner-buttons button {
    touch-action: manipulation;
  }

  /* 防止 iOS 上的文本自动缩放 */
  body {
    -webkit-text-size-adjust: 100%;
  }

  /* 修复 iOS 上固定定位元素的问题 */
  /* .App-header {
    -webkit-transform: translateZ(0);
  } */
}

.shopping-bag-dropdown-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #1d1d1f;
  border-top: 1px solid #424245;
  z-index: 1000;
}

.shopping-bag-dropdown-content {
  max-width: 980px;
  margin: 0 auto;
  padding: 40px 22px;
  color: #f5f5f7;
  text-align: left;
}

.shopping-bag-dropdown-content h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.shopping-bag-dropdown-content .view-recent,
.shopping-bag-dropdown-content .account-title {
  margin-bottom: 30px;
  color: #86868b;
  font-size: 14px;
}

.shopping-bag-dropdown-content .login-link {
  color: #2997ff;
  text-decoration: none;
  font-size: 14px;
  margin-right: 5px;
}

.shopping-bag-dropdown-content .account-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.shopping-bag-dropdown-content .account-links li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.shopping-bag-dropdown-content .account-links li svg {
  margin-right: 10px;
  font-size: 18px;
  color: #86868b;
}

.shopping-bag-dropdown-content .account-links a {
  color: #f5f5f7;
  text-decoration: none;
  font-size: 14px;
}

.shopping-bag-dropdown-content .account-links a:hover {
  text-decoration: underline;
}

@media (max-width: 1068px) {
  .shopping-bag-dropdown-content {
    padding: 40px 22px;
  }
}

.main-header {
  background-color: rgba(0, 0, 0, 0.8);
  color: #f5f5f7;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icon {
  color: #f5f5f7;
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;
}

.shopping-bag {
  cursor: pointer;
}

.menu-icon {
  display: none;
  cursor: pointer;
  margin-left: 15px;
}

.menu-icon-bar {
  width: 18px;
  height: 2px;
  background-color: #f5f5f7;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .header-center {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #424245;
  background-color: #1d1d1f;
  color: #f5f5f7;
}

.quick-links h4 {
  font-size: 12px;
  color: #86868b;
  margin-bottom: 10px;
}

.quick-links ul {
  list-style-type: none;
  padding: 0;
}

.quick-links li {
  margin-bottom: 10px;
}

.quick-links a {
  color: #2997ff;
  text-decoration: none;
  font-size: 14px;
}

.quick-links a:hover {
  text-decoration: underline;
}

body {
  user-select: auto !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
}

.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.mobile-menu-overlay.open {
  display: block;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #1d1d1f;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
}

.mobile-menu.open {
  right: 0;
}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #f5f5f7;
  font-size: 24px;
  cursor: pointer;
}

.footer-bottom .qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.footer-bottom .qr-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.footer-bottom .qr-content img {
  max-width: 400px;
  max-height: 400px;
}
