.blog-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-post h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .post-date {
    color: #666;
    margin-bottom: 20px;
  }
  
  .post-content {
    line-height: 1.6;
  }