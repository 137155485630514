.bouncing-ball-loader {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 50px auto;
  }
  
  .ball {
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: radial-gradient(circle at 30% 30%, #ffffff, #e0e0e0);
    left: 25px;
    bottom: 25px;
    animation: bounce 2.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    z-index: 2;
  }
  
  .shadow {
    position: absolute;
    width: 70px;
    height: 14px;
    background: radial-gradient(ellipse, rgba(0,0,0,0.2), rgba(0,0,0,0));
    border-radius: 50%;
    bottom: -20px;
    left: 25px;
    animation: shadow 2.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    z-index: 1;
  }
  
  @keyframes bounce {
    0%, 100% {
      bottom: 25px;
      height: 66px;
      width: 74px;
    }
    50% {
      bottom: 65px;
      height: 70px;
      width: 70px;
    }
  }
  
  @keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 0.4;
    }
    50% {
      transform: scaleX(0.8);
      opacity: 0.2;
    }
  }