.profitability-analysis {
  background-color: #f5f5f7;
  min-height: 100vh;
  padding: 40px 0;
}

.pa-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
}

.pa-title {
  font-size: 48px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 40px;
  color: #1d1d1f;
}

.pa-back-button {
  background-color: #0071e3;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 980px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: -20px;
  margin-bottom: 30px;
  align-self: flex-start;
}

.pa-back-button:hover {
  background-color: #0077ed;
}

.pa-content {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.pa-search-section {
  flex: 0 0 40%;
}

.pa-results-section {
  flex: 1;
  position: relative;
  top: -20px;
}

.pa-search {
  position: relative;
  z-index: 10;
}

.pa-search-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;
}

.pa-search-results-container {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #d2d2d7;
  border-top: none;
  border-radius: 0 0 8px 8px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 11;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pa-search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pa-search-results li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.pa-search-results li:last-child {
  border-bottom: none;
}

.pa-search-results li:hover {
  background-color: #f5f5f7;
}

.pa-results {
  background-color: #fff;
  padding: 30px;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-top: 0;
}

.pa-results h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 20px;
  text-align: center;
}

.pa-result-item {
  margin-bottom: 16px;
}

.pa-result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.pa-result-label {
  color: #86868b;
  font-size: 14px;
  flex: 1;
}

.pa-result-value {
  font-weight: 600;
  color: #1d1d1f;
  font-size: 16px;
  text-align: right;
  min-width: 80px;
}

.pa-expand-icon {
  font-size: 12px;
  transition: transform 0.3s;
}

.pa-expand-icon.expanded {
  transform: rotate(180deg);
}

.pa-result-details {
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f7;
  border-radius: 8px;
}

.pa-result-divider {
  border: none;
  border-top: 1px solid #d2d2d7;
  margin: 10px 0;
}

.pa-loading, .pa-error, .pa-no-results, .pa-debug {
  text-align: left;
  padding-left: 10px;
}

.pa-error {
  color: #ff3b30;
}

.pa-debug {
  font-size: 12px;
  color: #86868b;
  margin-top: 5px;
}

.pa-results-section {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .pa-title {
    font-size: 32px;
  }

  .pa-content {
    flex-direction: column;
  }

  .pa-search-section,
  .pa-results-section {
    max-width: 100%;
  }

  .pa-search-results-container {
    position: static;
    max-height: none;
    border: none;
    box-shadow: none;
  }

  .pa-results {
    margin-top: 20px;
  }

  .pa-results h2 {
    font-size: 20px;
  }

  .pa-result-value {
    font-size: 14px;
  }
}
