.blog-admin {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-admin h1 {
    text-align: center;
  }
  
  .blog-admin form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .blog-admin input,
  .blog-admin textarea {
    padding: 10px;
    font-size: 16px;
  }
  
  .blog-admin textarea {
    height: 200px;
  }
  
  .blog-admin button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .blog-admin button:hover {
    background-color: #0056b3;
  }
  
  .post-list {
    margin-top: 30px;
  }
  
  .post-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    padding-right: 80px;
  }
  
  .post-item h3 {
    margin-top: 0;
  }
  
  .post-item span {
    margin-right: 10px;
    font-size: 14px;
    color: #666;
  }
  
  .post-item button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .post-item button:hover {
    background-color: #ff0000;
  }
  
  .ql-container {
    height: 300px;
    margin-bottom: 20px;
  }

  .ql-editor {
    font-size: 16px;
  }