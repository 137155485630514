.stock-essence {
  background-color: #f5f5f7;
  min-height: 100vh;
  padding: 40px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.stock-essence h1 {
  text-align: center;
  margin-bottom: 20px;
}

.stock-essence button {
  margin-bottom: 20px;
}

.stock-item {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.stock-item h2 {
  margin-top: 0;
}

.se-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  text-align: left;
}

.se-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #1d1d1f;
}

.se-title-highlight {
  color: #0071e3;
}

.se-back-button {
  background-color: #0071e3;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 30px;
}

.se-back-button:hover {
  background-color: #0077ed;
}

.se-content {
  display: flex;
  gap: 40px;
}

.se-search-section {
  flex: 0 0 40%;
  position: relative;
  text-align: left;
}

.se-search-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  background-color: #fff;
}

.se-search-results {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #d2d2d7;
  border-top: none;
  border-radius: 0 0 8px 8px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.se-search-results li {
  padding: 10px;
  cursor: pointer;
}

.se-search-results li:hover {
  background-color: #f5f5f7;
}

.se-results-section {
  flex: 1;
}

.se-results {
  background-color: #fff;
  padding: 30px;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.se-results h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 20px;
  text-align: center;
}

.se-result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #d2d2d7;
}

.se-result-item:last-child {
  border-bottom: none;
}

.se-result-label {
  color: #86868b;
  font-size: 14px;
  text-align: left;
}

.se-result-value {
  font-weight: 600;
  color: #1d1d1f;
  font-size: 16px;
  text-align: right;
  word-break: break-word;
  max-width: 60%;
}

.se-error {
  color: #ff3b30;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .se-title {
    font-size: 32px;
  }

  .se-content {
    flex-direction: column;
  }

  .se-search-section {
    flex: 1 1 auto;
    width: 100%;
  }

  .se-search-input {
    width: 100%;
    box-sizing: border-box;
  }
}

.search-container {
  position: relative;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

/* 复制 ProfitabilityAnalysis.css 的内容到这里 */
/* 然后添加或修改以下样式 */

.stock-essence .pa-results {
  margin-top: 20px;
}

.stock-essence .pa-result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.stock-essence .pa-result-label {
  color: #86868b;
  font-size: 14px;
}

.stock-essence .pa-result-value {
  font-weight: 600;
  color: #1d1d1f;
  font-size: 16px;
}

.stock-essence.profitability-analysis {
  background-color: #f5f5f7;
  min-height: 100vh;
  padding: 40px 0;
}

.pa-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 22px;
}

.pa-title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1d1d1f;
}

.pa-back-button {
  background-color: #0071e3;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 980px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 30px;
}

.pa-back-button:hover {
  background-color: #0077ed;
}

.pa-content {
  display: flex;
  gap: 40px;
}

.pa-search-section {
  flex: 0 0 40%;
}

.pa-results-section {
  flex: 1;
}

.pa-search {
  position: relative;
}

.pa-search-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  background-color: #fff;
}

.pa-search-results-container {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #d2d2d7;
  border-top: none;
  border-radius: 0 0 8px 8px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
}

.pa-search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pa-search-results li {
  padding: 10px;
  cursor: pointer;
}

.pa-search-results li:hover {
  background-color: #f5f5f7;
}

.pa-results {
  background-color: #fff;
  padding: 30px;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pa-results h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 20px;
  text-align: center;
}

.pa-result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pa-result-label {
  color: #86868b;
  font-size: 14px;
}

.pa-result-value {
  font-weight: 600;
  color: #1d1d1f;
  font-size: 16px;
}

.pa-loading, .pa-error {
  padding: 10px;
  text-align: center;
}

.pa-error {
  color: #ff3b30;
}

@media (max-width: 768px) {
  .pa-title {
    font-size: 32px;
  }

  .pa-content {
    flex-direction: column;
  }

  .pa-search-section,
  .pa-results-section {
    width: 100%;
  }
}

.se-wrapper {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
}

.se-manager-item {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f7;
  border-radius: 8px;
}

.se-manager-item h4 {
  margin-top: 0;
  color: #1d1d1f;
}

.se-manager-item p {
  margin: 5px 0;
  color: #515154;
}

.se-manager-info {
  display: flex;
  justify-content: space-between;
}

.se-manager-basic,
.se-manager-salary {
  width: 48%;
}

.se-manager-salary h5 {
  margin-top: 0;
  color: #1d1d1f;
}

.se-salary-item {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.se-salary-item p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .se-manager-info {
    flex-direction: column;
  }

  .se-manager-basic,
  .se-manager-salary {
    width: 100%;
  }

  .se-manager-salary {
    margin-top: 20px;
  }
}

.se-manager-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-expand-icon {
  transition: transform 0.3s ease;
}

.se-expand-icon.expanded {
  transform: rotate(180deg);
}

.se-manager-info {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.se-industry-item {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.se-industry-item p {
  margin: 5px 0;
  color: #333;
}

.se-section {
  background-color: #ffffff;
  border-radius: 18px;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.se-section h3 {
  color: #1d1d1f;
  font-size: 24px;
  margin-bottom: 15px;
}

.se-info-container {
  display: flex;
  flex-direction: column;
}

.se-result-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #d2d2d7;
}

.se-result-item:last-child {
  border-bottom: none;
}

.se-result-label {
  color: #515154;
  font-weight: normal;
}

.se-result-value {
  font-weight: 600;
  color: #1d1d1f;
  text-align: right;
}

.se-profit-statement {
  margin-top: 20px;
}

.se-profit-statement-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-profit-statement-content {
  background-color: #f5f5f7;
  border-radius: 8px;
  padding: 10px;
}

.se-profit-statement .se-result-item {
  background-color: transparent;
}

.se-balance-sheet {
  margin-top: 20px;
}

.se-balance-sheet-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-balance-sheet-content {
  background-color: #f5f5f7;
  border-radius: 8px;
  padding: 10px;
}

.se-balance-sheet .se-result-item {
  background-color: transparent;
}

.se-cashflow {
  margin-top: 20px;
}

.se-cashflow-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-cashflow-content {
  background-color: #f5f5f7;
  border-radius: 8px;
  padding: 10px;
}

.se-cashflow .se-result-item {
  background-color: transparent;
}

.se-financial-indicator {
  margin-top: 20px;
}

.se-financial-indicator-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-financial-indicator-content {
  background-color: #f5f5f7;
  border-radius: 8px;
  padding: 10px;
}

.se-financial-indicator .se-result-item {
  background-color: transparent;
}

.se-main-business {
  margin-top: 20px;
}

.se-main-business-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-main-business-content {
  background-color: #f5f5f7;
  border-radius: 8px;
  padding: 10px;
}

.se-main-business-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
}

.se-main-business-item h5 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #1d1d1f;
}

.se-main-business .se-result-item {
  background-color: transparent;
}

.se-market-performance {
  margin-top: 20px;
}

.se-market-performance-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e5e5ea;
  border-radius: 8px;
  margin-bottom: 10px;
}

.se-market-performance-content {
  background-color: #f5f5f7;
  border-radius: 8px;
  padding: 10px;
}

.se-market-performance .se-result-item {
  background-color: transparent;
}

.se-result-label-group {
    display: flex;
    flex-direction: column;
    max-width: 170px;
    width: 170px;
}

.se-result-description {
    font-size: 12px;
    color: #86868b;
    margin-top: 2px;
    text-align: left;
}

.se-market-performance .se-result-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
}

.se-market-performance .se-result-value {
    text-align: right;
    font-weight: 600;
    flex: 1;
}

@media (max-width: 768px) {
  .se-content {
    flex-direction: column;
  }

  .se-search-section {
    flex: 1 1 auto;
    margin-bottom: 20px;
  }

  .se-search-input {
    width: 100%;
    box-sizing: border-box;
  }
}

.se-date-picker {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.se-date-picker label {
  margin-right: 10px;
}

.se-date-picker input {
  padding: 5px;
  border: 1px solid #d2d2d7;
  border-radius: 4px;
}

.se-date-info {
  font-size: 14px;
  color: #86868b;
  margin-bottom: 10px;
}

.se-date-picker select {
    padding: 5px;
    border: 1px solid #d2d2d7;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
}

.se-market-performance-content {
    overflow-x: auto;
    white-space: nowrap;
}

.se-market-performance-table {
    width: 100%;
    border-collapse: collapse;
}

.se-market-performance-table th,
.se-market-performance-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    white-space: nowrap;
}

.se-market-performance-table th {
    background-color: #f5f5f7;
    font-weight: 600;
}

.se-market-performance-table tr:hover {
    background-color: #f0f0f2;
}

.se-market-performance-content {
    overflow-x: auto;
    position: relative;
    padding-left: 10px;
}

.se-market-performance-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.se-market-performance-table th.fixed-column,
.se-market-performance-table td.fixed-column {
    position: sticky;
    left: 0;
    background-color: #f5f5f7;
    z-index: 2;
    padding-left: 10px;
}

.se-market-performance-table th.fixed-column {
    z-index: 3;
}

.se-market-performance-table th.fixed-column::before,
.se-market-performance-table td.fixed-column::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 10px;
    height: 100%;
    background-color: #f5f5f7;
}

.se-market-performance-table th.right-align,
.se-market-performance-table td.right-align {
    text-align: right;
}

.se-market-performance-table th.fixed-column,
.se-market-performance-table td.fixed-column {
    text-align: left;
}

.se-loading {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #666;
}
