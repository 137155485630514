.search-container {
    position: relative;
}

.search-icon {
    cursor: pointer;
    color: #333;
}

.search-overlay {
    position: fixed;
    top: 200px;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
}

.search-content {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
}

.search-dropdown {
    width: 100%;
    background-color: transparent;
    padding: 10px;
}

.search-dropdown h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.search-input {
    width: 100%;
    padding: 10px 0;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    margin-bottom: 10px;
}

.search-input::placeholder {
    color: #999;
}

.quick-links {
    margin-top: 30px;
}

.quick-links h3 {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
    text-transform: capitalize;
    text-align: left;
}

.quick-links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    text-align: left;
}

.quick-links-grid a {
    color: #333333 !important;
    text-decoration: none;
    font-size: 18px !important;
    font-weight: bold;
    transition: color 0.3s ease;
}

.quick-links-grid a:hover {
    color: #0066cc !important;
    text-decoration: none;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #0066cc;
}

@media (max-width: 768px) {
    .search-overlay {
        padding: 20px;
    }

    .search-content {
        padding: 0;
    }

    .quick-links-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

.search-text,
.close-icon {
    display: inline-block;
}

@media (max-width: 768px) {
    .search-text {
        font-size: 20px;
    }

    .close-icon {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .search-icon::before {
        content: none;
    }
}