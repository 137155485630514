.blog-page {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  /* border-bottom: 1px solid #d2d2d7; */
  padding-bottom: 10px;
}

.blog-header-left {
  font-size: 20px;
  font-weight: bold;
}

.blog-header-right {
  display: flex;
  font-size: 15px;
  gap: 20px;
  align-items: center;
  position: relative;
}

.blog-header-right a {
  text-decoration: none;
  color: #333;
}

.search-link {
  display: flex;
  align-items: center;
}

.search-icon {
  margin-right: 5px;
}

.blog-post {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  background-color: #f5f5f7;
}

.blog-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.blog-post-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  color: white;
  text-align: left;
}

.blog-post-tag {
  font-size: clamp(10px, 1vw, 14px);
  margin-bottom: 5px;
}

.blog-post-content h2 {
  font-size: clamp(16px, 2vw, 32px);
  margin: 0 0 5px 0;
  text-align: left;
}

.blog-post-content p {
  font-size: clamp(12px, 1.5vw, 16px);
  margin: 0;
  text-align: left;
}

.main-post .blog-post-content h2 {
  font-size: clamp(24px, 3vw, 40px);
}

.main-post .blog-post-content p {
  font-size: clamp(14px, 1.8vw, 18px);
}

.blog-page.blur {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

.blog-content.blur {
  width: 980px;
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .blog-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-header-right {
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .blog-post-content h2,
  .main-post .blog-post-content h2,
  .secondary-post .blog-post-content h2,
  .grid-post .blog-post-content h2 {
    font-size: 24px !important;
  }

  .blog-post-content p,
  .main-post .blog-post-content p,
  .secondary-post .blog-post-content p,
  .grid-post .blog-post-content p {
    font-size: 14px !important;
  }

  .blog-post-tag {
    font-size: 12px !important;
  }

  .blog-grid,
  .secondary-posts,
  .grid-posts {
    gap: 15px;
  }
}

.blog-content h1 {
  text-align: left;
  margin-bottom: 30px;
}

.blog-post-content h2 {
  font-size: clamp(16px, 2vw, 32px);
  margin-bottom: 10px;
}

.blog-post-content p {
  font-size: clamp(12px, 1.5vw, 16px);
}

.main-post {
  width: 100%;
  max-width: 980px;
  aspect-ratio: 16 / 9;
  margin-bottom: 20px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f5f5f7;
}

.main-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.main-post .blog-post-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  color: white;
  text-align: left;
}

.main-post .blog-post-tag {
  font-size: clamp(10px, 1vw, 14px);
  margin-bottom: 5px;
}

.main-post .blog-post-content h2 {
  font-size: clamp(24px, 3vw, 40px);
  margin: 0 0 5px 0;
  text-align: left;
}

.main-post .blog-post-content p {
  font-size: clamp(14px, 1.8vw, 18px);
  margin: 0;
  text-align: left;
}

@media (max-width: 768px) {
  .two-column-posts {
    flex-direction: column;
  }
  
  .two-column-posts .blog-post {
    height: auto;
    margin-bottom: 20px;
  }
}

.blog-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}

.main-post {
  width: 100%;
  max-width: 980px;
  aspect-ratio: 16 / 9;
  margin-bottom: 20px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f5f5f7;
}

.secondary-posts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.secondary-post {
  aspect-ratio: 16 / 9;
}

.grid-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-post {
  aspect-ratio: 1 / 1;
}

@media (max-width: 768px) {
  .secondary-posts,
  .grid-posts {
    grid-template-columns: 1fr;
  }
  
  .grid-post {
    aspect-ratio: 16 / 9;
  }
}

.blog-grid-item {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 3 / 2;
}

.blog-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-grid-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%);
  color: white;
  text-align: left;
}

.blog-grid-content h3 {
  font-size: 16px;
  margin: 5px 0;
  text-align: left;
}

.blog-grid-content p {
  font-size: 12px;
  margin: 0;
  text-align: left;
}

@media (max-width: 768px) {
  .blog-grid {
    gap: 10px;
  }

  .secondary-posts,
  .grid-posts {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .blog-grid {
    grid-template-columns: 1fr;
  }
}

.secondary-post .blog-post-content h2 {
  font-size: clamp(18px, 2.2vw, 28px);
}

.secondary-post .blog-post-content p {
  font-size: clamp(12px, 1.5vw, 16px);
}

.grid-post .blog-post-content h2 {
  font-size: clamp(14px, 1.8vw, 24px);
}

.grid-post .blog-post-content p {
  font-size: clamp(10px, 1.2vw, 14px);
}

.blog-content {
  padding: 0 10px;
}

.main-post {
  width: 100%;
  max-width: 980px;
  aspect-ratio: 16 / 9;
  margin-bottom: 20px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f5f5f7;
}

@media (max-width: 768px) {
  .main-post {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blog-page {
    width: 100%;
    padding: 0 10px;
  }
  
  .main-post {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 980px) {
  .blog-page,
  .blog-content,
  .blog-grid,
  .main-post {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}

@media (min-width: 981px) {
  .blog-page,
  .blog-content,
  .blog-grid,
  .main-post {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
  }
}

.blog-content {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.main-post-wrapper {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}