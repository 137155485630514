.customer-support {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.support-item {
  display: flex;
  align-items: center;
}

.support-icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.support-text p {
  margin: 0;
  font-size: 12px;
  color: #1d1d1f;
}

.support-text a {
  color: #0066cc;
  text-decoration: none;
  font-size: 12px;
}

@media (max-width: 768px) {
  .customer-support {
    margin-top: 30px;
    align-items: flex-start;
  }
}

.qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-content {
  background-color: white;
  padding: 20px;
  border-radius: 18px;
  text-align: center;
  position: relative;
}

.qr-content img {
  max-width: 400px;
  max-height: 400px;
}

.pay-qr-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
}

.pay-qr-content img {
  max-width: 200px;
  /* height: auto; */
  border-radius: 10px;
  margin-bottom: 20px;
}

.pay-qr-content img:last-child {
  margin-bottom: 0;
}
