.software-grid-container {
  overflow-x: auto;
  padding: 20px 0;
  -webkit-overflow-scrolling: touch;
}

.software-grid {
  display: flex;
  gap: 20px;
  padding: 0 20px;
  min-width: min-content;
}

.software-item {
  flex: 0 0 auto;
  width: 150px;
  text-align: center;
}

.software-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.software-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.software-item h3 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
}

@media (max-width: 768px) {
  .software-grid {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .software-item {
    width: 120px;
  }
}